import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getReportList,changeReportStatus,deleteReport} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';

export default function ReportListing(props) {

    const [myReportList, setReportList] = useState(null)

    useEffect(() => {
        
        let mounted = true;
        props.isLoader(true)
        getReportList({"page":"1","limit":"1000","sort_by":"id","order_by":"desc","school_ids":localStorage.getItem("MASschoolListId").replace("]","").replace("[",""),"role":JSON.parse(localStorage.getItem("MASuserData")).role,"role_id":localStorage.getItem("MASid")}).then((resposnse)=>{
            // console.log(resposnse)
            if(resposnse.data.code==1){
                setReportList(resposnse.data.data.list);
            }else{
                setReportList([]);
            }
        })
        return () => mounted = false;
    }, []);


    $(document).ready(function () {
        $('#mangeReport').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  order: [[0, 'desc']],
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });

    let navigate = useNavigate(); 

    const statusChangeListner = (position)=>{

        // console.log(position) 
 
        props.isLoader(true)
 
        // console.log("Call api change status")
 
        let apiData={}
        if(myReportList[position].is_active===1){
             apiData={"report_id":myReportList[position].id,"status":0}        
        }
        else{
             apiData={"report_id":myReportList[position].id,"status":1}        
        }
 
        // // console.log(apiData)
 
        changeReportStatus(apiData).then((resposnse)=>{
        //  // console.log(resposnse)
         props.isLoader(false)
         if(resposnse.data.code==1){
             callAfterApiChangeStatus(position,true)
         }else{
             callAfterApiChangeStatus(position,false)
         }
         })    
     }
 
     const callAfterApiChangeStatus = (position,status)=>{
 
         if(status){
 
             if(myReportList[position].is_active===1){
                 myReportList[position].is_active=0       
                }else{
                 myReportList[position].is_active=1
                 }
         }
 
         setReportList(myReportList)
 
         manageCheckStatus(position)
 
     }
 
     const manageCheckStatus = (position)=>{
         // console.log(position) 
  
        if(myReportList[position].is_active===1){
         // console.log("set Check")
         $("#" + 'status'+position).attr("checked", true);        
        }else{
         // console.log("Set Uncheck")
         $("#" + 'status'+position).attr("checked", false);        
         }
     }

     function deleteReportIdWise(reportId){
        // console.log("Call Delete"+reportId)
        if(window.confirm("Are you sure you want to delete?")){
            props.isLoader(true)
            deleteReport({"report_id":reportId}).then((resposnse)=>{
                // console.log(resposnse);
                if(resposnse.data.code==1){
                    $('#mangeReport').DataTable().row("#reportId"+reportId).remove().draw();
                }else{
                }
                props.isLoader(false)
            });
        }
    }
    
    if (myReportList==null) return null;
    props.isLoader(false)

    return (
    <section className="content home">
        <Heading title="Report"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    </div>
                    <div className="body">
                    <table id="mangeReport" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Serial number</th>
                                    <th>Type Of Data</th>
                                    <th>Reason for reporting</th>
                                    <th>Description</th>
                                    <th>Reported content</th>
                                    <th>Reported by </th>
                                    <th>Reported against</th>
                                    <th>School id</th>
                                    <th>Class id</th>
                                    <th>Division id</th>
                                    <th>Take Action</th>
                                    <th>Action taken by</th>                                
                                    <th>Status of action</th>
                                    <th>Delete Report</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Serial number</th>
                                    <th>Type Of Data</th>
                                    <th>Reason for reporting</th>
                                    <th>Description</th>
                                    <th>Reported content</th>
                                    <th>Reported by </th>
                                    <th>Reported against</th>
                                    <th>School id</th>
                                    <th>Class id</th>
                                    <th>Division id</th>
                                    <th>Take Action</th>
                                    <th>Action taken by</th>                                
                                    <th>Status of action</th>
                                    <th>Delete Report</th>
                                 </tr>
                            </tfoot>
                            <tbody>

                            { 
                            myReportList && myReportList.map((item,index)=>{
                                    return(
                                        <tr id={"reportId"+item.id}>
                                            <td>{item.created_at}</td>
                                            <td>{item.id}</td>
                                            <td>{item.type}</td>
                                            <td>{item.subject}</td>
                                            <td>{item.description}</td>
                                            <td>{
                                            item.reported_content}
                                            <br/>
                                           { (item.type=="comment")?
                                            <>
                                            <b>post:</b> {item.reported_comment_for_post}
                                            </>
                                            :
                                            <></>
                                            }

                                            </td>
                                            <td>{item.from_user_id}</td>
                                            <td>{item.to_user_id}</td>
                                            <td>{item.school_id}</td>
                                            <td>{item.class_id}</td>
                                            <td>{item.division_id}</td>
                                            <td className="text-center">
                                            <div className='action-btn'>                        
                                                <Link to={`/changeReportStatus/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-edit"></i></Link>
                                            </div>    
                                            </td>
                                            <td>{(item.action_taken_by_user_id==null ? "" :item.action_taken_by_user_id )}</td>                                
                                            <td>{item.status}</td>
                                            <td className="text-right">
                                            <div className='action-btn'>                        
                                                {/*<Link to={`/reportDetails/${item.id}`} className="btn btn-default col-green"><i className="zmdi zmdi-eye"></i></Link>*/}
                                                <a onClick={()=> {deleteReportIdWise(item.id)}} className="btn btn-default col-red"><i className="zmdi zmdi-delete"></i></a>
                                            </div>    
                                            </td>
                                        </tr>
                                        )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
