
export function loginRedirectCall(){
    let path= window.location.protocol+"//"+window.location.host+"/signIn" 
//    window.open(path,'mywindow').focus()
    window.location.href = path;
  }

export function homeRedirectCall(){
    let path= window.location.protocol+"//"+window.location.host+"/"
//    window.open(path,'mywindow').focus()
    window.location.href = path;  
}

export function logOutRedirectCall(){
  localStorage.removeItem("MASisLogin");
  localStorage.removeItem("MASuserData");
  localStorage.removeItem("MASid");
  localStorage.removeItem("MAStoken");
  localStorage.removeItem("MASname");
  localStorage.removeItem("MASemail");
  localStorage.removeItem("MASschoolList");
  localStorage.removeItem("MASschoolListId");

  loginRedirectCall()
}

export function loginRedirectCallWithDataStore(data){
  // console.log("Redirect Call")
  // console.log(data)
  localStorage.setItem("MASisLogin",true);
  localStorage.setItem("MASuserData",JSON.stringify(data));
  localStorage.setItem("MASid",data.id);
  localStorage.setItem("MAStoken",data.token);
  localStorage.setItem("MASname",data.name);
  localStorage.setItem("MASemail",data.email);
  homeRedirectCall()
}


  