import React,{useState,useEffect} from 'react'
import CaptureMonitoringComponents from './CaptureMonitoringComponents'
import CaptureMonitoringDetailsComponents from './CaptureMonitoringDetailsComponents'
import { useNavigate } from 'react-router-dom'

import {captureMonitoringList,captureMonitoringDataStatus,captureMonitoringContentStatus,captureMonitoringUserStatus,createReportFromMonitoring,createSendReportFromMonitoring} from '../../api/apiHandler'

export default function CaptureMonitoringList(props) {

    let navigate = useNavigate(); 

    const [dataList, setDataList] = useState([]);
    const [limit, setLimit] = useState(20);
    const [selectMonitoringData,setSelectMonitoringData]= useState(null);
    const [selectMonitoringDataIndex,setSelectMonitoringDataIndex]= useState(null);

    // // console.log("dataList");
    // // console.log(dataList);
    // // console.log("selectMonitoringData");
    // // console.log(selectMonitoringData);
    // // console.log("selectMonitoringDataIndex");
    // // console.log(selectMonitoringDataIndex);

    const[page,setPage] = useState(1);
    const[isLoading,setIsLoading] = useState(false);
    let timer;

    useEffect(() => {
        monitoringListAPICall();
    },[limit]);

    const clickDetails = (index,data)=>{
        setSelectMonitoringData(data);
        setSelectMonitoringDataIndex(index);
    }

    const clickProgress = (data) =>{
        apiCallUpdateInternalStatus(data.id,"Processing");
    }

    const clickAleart = (data) =>{
        apiCallUpdateInternalStatus(data.id,"Alert");

    }
    
    const clickUnaleart = (data) =>{
        apiCallUpdateInternalStatus(data.id,"Unalert");
    }

    const clickContentHide = (data) =>{
        apiCallUpdateContentStatus(data.id,"Hide")
    }

    const clickContentUnhide = (data) =>{
        apiCallUpdateContentStatus(data.id,"Unhide")
    }

    const clickUsertHide = (data) =>{
        apiCallUpdateUserStatus(data.id,"Active")
    }

    const clickUserUnhide = (data) =>{
        apiCallUpdateUserStatus(data.id,"Inactive")
    }

    const clickCreateReport = (data) =>{
        apiCallCreateReport(data)
    }

    const clickViewReport = (data) =>{
        navigate("/reportListing");

    }

    const clickSendReport = (data) =>{
        // console.log("send Report")
        apiCallSendReport(data.id)
    }

    const apiCallUpdateInternalStatus = (id,status)=>{
        props.isLoader(true);
        captureMonitoringDataStatus({"capture_data_id":id,"status":status}).then((resposnse)=>{
            // console.log(resposnse)
            if(resposnse.data.code==1){
                selectMonitoringData.status=status;
              //  setPostList(resposnse.data.data.list);
              setSelectMonitoringData(selectMonitoringData);
            }
            props.isLoader(false);
        })
    }

    const apiCallUpdateContentStatus = (id,status)=>{
        props.isLoader(true);
        captureMonitoringContentStatus({"capture_data_id":id,"content_status":status}).then((resposnse)=>{
            // console.log(resposnse)
            if(resposnse.data.code==1){
                selectMonitoringData.content_status=status;
              //  setPostList(resposnse.data.data.list);
              setSelectMonitoringData(selectMonitoringData);
            }
            props.isLoader(false);
        })
    }

    const apiCallUpdateUserStatus = (id,status)=>{
        props.isLoader(true);
        captureMonitoringUserStatus({"capture_data_id":id,"user_status":status}).then((resposnse)=>{
            // console.log(resposnse)
            if(resposnse.data.code==1){
                selectMonitoringData.user_status=status;
               setSelectMonitoringData(selectMonitoringData);
            }
            props.isLoader(false);
        })
    }

    const apiCallCreateReport = (data)=>{
        props.isLoader(true);
        createReportFromMonitoring({...data, "capture_data_id":data.id}).then((resposnse)=>{
            // console.log(resposnse)
            if(resposnse.data.code==1){
                selectMonitoringData['report_status']="Created";
               setSelectMonitoringData(selectMonitoringData);
            }
            props.isLoader(false);
        })

    }

    const apiCallSendReport = (id)=>{
        props.isLoader(true);
        createSendReportFromMonitoring({"captured_data_id":id}).then((resposnse)=>{
            // console.log(resposnse)
            if(resposnse.data.code==1){
                selectMonitoringData['report_status']="Sent";
               setSelectMonitoringData(selectMonitoringData);
            }
            props.isLoader(false);
        })

    }

    const monitoringListAPICall= async ()=>{
        if(!isLoading){
            setIsLoading(true)
            const schoolId = localStorage.getItem("MASschoolListId").replace("]","").replace("[","");
            const theRole = JSON.parse(localStorage.getItem("MASuserData")).role;
            const roleId = localStorage.getItem("MASid");
            const resposnse = await captureMonitoringList(
                {
                    "page":page,
                    "limit":limit,
                    "sort_by":"id",
                    "order_by":"desc",
                    "school_ids":schoolId,
                    "role":theRole,
                    "role_id":roleId
                }).then((resposnse)=>{return resposnse;});
            if(resposnse.data.code==1) setDataList(resposnse.data.data.list);
            setIsLoading(false)
            setPage(page+1)
        }
        
    }

    window.onscroll = function(ev) {
        // console.log("Scroll api call"+isLoading);
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            if(!isLoading){
            //monitoringListAPICall();
        }
        }
    };

    const limitIncrease = () => {
        setLimit(limit+20);
    }

    const seeMore = () => {
        if (!isLoading && dataList.length == limit) {
            return (
                <>
                <center>
                    <button type="button" class="btn btn-warning" onClick={limitIncrease}>See More...</button>
                </center>
                <br />
                </>
            );
        }
    }

    useEffect(() => {
        monitoringListAPICall();
        return () => {
            window.onscroll= function(ev){
                
            }
            // console.log("Api call block using flag");
          };
    }, []);

  return (
    <section className="content home">   
    <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-6 m-t-15">
                <div className="card">
                    <div className="header align-center">
                        <h2>Capture Monitoring Data</h2>
                    </div>
                    <CaptureMonitoringComponents data={dataList} viewClick={clickDetails} />
                    {seeMore()}
                </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 m-t-15">
            <div className="card sticky-panel">

            <CaptureMonitoringDetailsComponents data={selectMonitoringData} buttonP={clickProgress} buttonA={clickAleart} buttonUA={clickUnaleart}
            buttonCH={clickContentHide} buttonCUH={clickContentUnhide}
            buttonUH={clickUsertHide} buttonUUH={clickUserUnhide}
            buttonCR={clickCreateReport} buttonVR={clickViewReport}
            buttonSR={clickSendReport}
            />

            </div>
            </div>
        </div>
    </div>
    </section>
)
}
