import React, { useState } from 'react'
import Select from 'react-select'

export default function CaptureMonitoringDetailsComponents(props) {

       const [createReportStatus, setCreateReportStatus] = useState(false);
       const [subject, setSubject] = useState('');

       const report_subjectList = [
              { value: "Criticality level 1 - Abusive / Imminent danger", label: "Criticality level 1 - Abusive / Imminent danger" },
              { value: "Criticality level 2 - Anonymous / Suspicious", label: "Criticality level 2 - Anonymous / Suspicious" },
              { value: "Criticality level 3 - Bullying / Bad language", label: "Criticality level 3 - Bullying / Bad language" },
              { value: "Criticality level 4 - Well-being Related", label: "Criticality level 4 - Well-being Related" },
       ]

       const handleSubjectChange=(data)=>{
              setSubject(data)
       }

       const rendarInternalStatus = ()=>{
              if(props.data.status==="Processing"){ 

              return (<>
                            <button type="button" class="btn btn-raised btn-danger waves-effect m-1" onClick={()=>props.buttonA(props.data)}>Alert</button>
                            <button type="button" class="btn btn-raised btn-success waves-effect m-1" onClick={()=>props.buttonUA(props.data)}>Resolved</button>
                     </>)

              }else if(props.data.status==="Alert"){
                     return (<>
                             <button type="button" class="btn btn-raised btn-warning waves-effect m-1" onClick={()=>props.buttonP(props.data)}>Processing</button>
                             <button type="button" class="btn btn-raised btn-success waves-effect m-1" onClick={()=>props.buttonUA(props.data)}>Resolved</button>
                     </>)

              }else{
                     return (<>
                            <button type="button" class="btn btn-raised btn-warning waves-effect m-1" onClick={()=>props.buttonP(props.data)}>Processing</button>
                            <button type="button" class="btn btn-raised btn-danger waves-effect m-1" onClick={()=>props.buttonA(props.data)}>Alert</button>
                     </>)
              }
       }

       const renderContentHide = () =>{
                      if(props.data.content_status==="Unhide"){ 
                     return (<>
                                   <button type="button" class="btn btn-raised btn-danger waves-effect m-1" onClick={()=>props.buttonCH(props.data)}>Content Hide</button>
                            </>)
       
                     }else{
                            return (<>
                                   <button type="button" class="btn btn-raised btn-success waves-effect m-1" onClick={()=>props.buttonCUH(props.data)}>Content Unhide</button>
                            </>)
                     }
       }

       const renderUserHide = () =>{
              if(props.data.user_status === "Active"){ 
                     return (<>
                                   <button type="button" class="btn btn-raised btn-danger waves-effect m-1" onClick={()=>props.buttonUUH(props.data)}>User Disable</button>
                            </>)
       
                     }else{
                            return (<>
                                   <button type="button" class="btn btn-raised btn-success waves-effect m-1" onClick={()=>props.buttonUH(props.data)}>User Enable</button>
                            </>)
                     }
       }

       const renderReport = () =>{
              if(props.data.report_status === "Pending"){ 
                     return (<>
                            {createReportStatus ? reportReasons() : <button type="button" class="btn btn-raised btn-danger waves-effect m-1" onClick={() => {setCreateReportStatus(true)}}>Create Report</button>}
                     </>)
       
              }
              if(props.data.report_status === "Created"){
                     return (<>
                            <button type="button" class="btn btn-raised btn-warning waves-effect m-1" onClick={()=>props.buttonVR(props.data)}>View Report</button>
                            <button type="button" class="btn btn-raised btn-warning waves-effect m-1" onClick={()=>props.buttonSR(props.data)}>Send Report</button>
                     </>)
              }
              if(props.data.report_status === "Sent"){
                     return (<>
                            <button type="button" class="btn btn-raised btn-warning waves-effect m-1" onClick={()=>props.buttonVR(props.data)}>View Report</button>
                            <button type="button" class="btn btn-raised btn-warning waves-effect m-1" onClick={()=>{}} disabled>Report Already Send</button>
                     </>)
              }   
       }

       const reportReasons = () => {
              return (
                     <div class="alert m-t-10">
                            <div className="body">
                                   <form id="form_validation" method="" onSubmit={()=>{}} action="#">
                                          <label>Create Report</label>
                                                 <div className="form-group">
                                                        <Select
                                                               value={subject}
                                                               onChange={handleSubjectChange}
                                                               options={report_subjectList}
                                                               name="selectSubject" 
                                                        />          
                                                 </div>
                                          {subject ? <button className="btn btn-raised btn-primary waves-effect" type="submit" onClick={(e)=>{e.preventDefault();props.buttonCR({ ...props.data, subject: subject.value })}}>CREATE</button> : ''}
                                   </form>                     
                            </div>
                     </div>
              )
       }

  return (
    <div className="body">
    <div className="tab-content">
        <div role="tabpanel" className="tab-pane active" id="official" aria-expanded="true">
        { 
            props.data && 
                    <>
                     <li className="list-group-item">{props.data.message}</li>
                     <ul>
                            <li className="list-group-item twocolumn"><b className="mr-2">Id : </b> {props.data.id}</li>      
                            <li className="list-group-item twocolumn"><b className="mr-2">Data Id : </b> {props.data.data_id}</li>
                     </ul>
                     {props.data.school_name? <ul>
                            <li className="list-group-item twocolumn"><b className="mr-2">School Name : </b> {props.data.school_name}</li>
                     </ul> : null}
                     {props.data.username ? <ul>
                            <li className="list-group-item twocolumn"><b className="mr-2">Username : </b> {props.data.username}</li>
                     </ul> : null}
                     <ul>
                            <li className="list-group-item"><b className="mr-2">Created : </b> {props.data.created_at}</li>  
                     </ul>
                     <ul>
                            <li className="list-group-item twocolumn"><b className="mr-2">Type : </b> {props.data.data_type}</li>    
                            <li className="list-group-item twocolumn"><b className="mr-2">School Id : </b> {props.data.school_id}</li>                           
                     </ul>
                     {props.data.data_type=="Chat" ? 
                     <ul>
                            <li className="list-group-item twocolumn"><b className="mr-2">Sender Id : </b> {props.data.user_id}</li>     
                            {props.data.sender_username ? <li className="list-group-item twocolumn"><b className="mr-2">Sender Username : </b> {props.data.sender_username}</li> : null}
                            <li className="list-group-item twocolumn"><b className="mr-2">Receiver Id : </b> {props.data.receiver_id}</li>
                            {props.data.receiver_username ? <li className="list-group-item twocolumn"><b className="mr-2">Receiver Username : </b> {props.data.receiver_username}</li> : null}
                     </ul>
                     :
                     <>
                     <li className="list-group-item"><b className="mr-2">User Id : </b> {props.data.user_id}</li>     
                     </>}

                     {props.data.data_type=="Comment" ? 
                     <>
                     <li className="list-group-item"><b className="mr-2">Post Id : </b> {props.data.post_id}</li>     
                     <li className="list-group-item"><b className="mr-2">Post Content : </b> {props.data.post_content}</li>     
                     
                     </>
                     :
                     <>
                     </>}


                     <li className="list-group-item"><b className="mr-2">Captured By : </b> {props.data.capture_by}</li>                          
                     {/* <li className="list-group-item"><b className="mr-2">Capture By Type : </b> {props.data.capture_by_type}</li>                           */}

                     <div class="alert bg-green m-t-10">Internal Status : <strong>{props.data.status==='Unalert' ? 'Resolved' : props.data.status}</strong></div>      

                     <p class="card-text align-center">
                            {rendarInternalStatus()}
                     </p>

                     <div class="alert bg-blue m-t-10">Cybersmarties Content Status : <strong>{props.data.content_status}</strong></div>      

                     <p class="card-text align-center">
                            {renderContentHide()}
                     </p>
                        

                     <div class="alert bg-red m-t-10">Cybersmarties User Status : <strong>{props.data.user_status}</strong></div>      

                     <p class="card-text align-center">
                            {renderUserHide()}
                     </p>
                     <div class="alert bg-red m-t-10">Reporting : <strong>{props.data.report_status}</strong></div>      

                     <p class="card-text align-center">
                            {renderReport()}
                     </p>
                                                              
                    </>                         
        }
        </div>
    </div>
</div>
  )
}


